import { fadeIn, fadeOut } from '../helpers/animations';

const mobile = () => {
  const toggle = document.querySelector('button.header__toggle');
  const menu = document.querySelector('nav.header__nav');

  toggle.addEventListener('click', () => {
    if (!menu.classList.contains('open')) {
      fadeIn(menu);
      menu.classList.add('open');
      document.body.classList.add('locked');
      return;
    }
    if (menu.classList.contains('open')) {
      fadeOut(menu);
      menu.classList.remove('open');
      document.body.classList.remove('locked');
      return;
    }
  });
};

export default mobile;
