const nav = () => {
  const toggle = document.querySelector('.lj-applications-specifics__item--chevron');
  const items = document.querySelectorAll('.lj-applications-specifics__item');

  if (items.length == 0) return;
  toggle.addEventListener('click', () => {
    items.forEach((item) => {
      item.classList.toggle('is-open');
    });
  });
};
export default nav;
