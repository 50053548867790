'use strict';

import nav from './includes/nav';
import mobile from './includes/mobile';
import specs from './includes/specs';
import timeline from './includes/timeline';
import typewriter from './includes/typewriter';
import { FAQDropdown } from './includes/faq';

document.addEventListener('DOMContentLoaded', () => {
  nav();
  specs();
  mobile();
  timeline();
  typewriter();
  FAQDropdown();
});

require('vanilla-lazyload');
