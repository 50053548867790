const timeline = () => {
  const tl = document.querySelector('.block.about-timeline');

  if (tl) {
    initTimeline();
    if (window.innerWidth < 961) scrollToActiveYear();
  }
};

function initTimeline() {
  const tlItems = [...document.querySelectorAll('.about-timeline__item')];
  const slides = [...document.querySelectorAll('.about-timeline__slide')];

  tlItems.forEach((item, index) => {
    item.addEventListener('click', (e) => {
      // Dots logic
      tlItems.forEach((el) => {
        el.classList.remove('active');
      });
      e.target.classList.add('active');

      // Slides logic
      slides.forEach((slide) => {
        slide.classList.remove('visible');
      });
      slides[index].classList.add('visible');
    });
  });
}
function scrollToActiveYear() {
  const tl = document.querySelector('.about-timeline__wrapper');
  const activeYear = document.querySelector('.about-timeline__item.active');
  const scrollXValue = activeYear.getBoundingClientRect().x - window.innerWidth / 2 + activeYear.offsetWidth / 2;

  tl.scrollTo(scrollXValue, 0);
}

export default timeline;
