import Typewriter from 'typewriter-effect/dist/core';

const typewriter = () => {
  const tw = document.querySelector('.block.about-dna');

  if (tw) initTypeWriter();
};
function initTypeWriter() {
  const twItems = [...document.querySelectorAll('.about-dna__value')];
  const strings = twItems.map((item) => {
    return item.innerText;
  });

  const instance = new Typewriter('#typewriter', {
    strings: strings,
    autoStart: true,
    loop: true,
    delay: 80,
  });
}

export default typewriter;
