import { fadeIn, fadeOut } from '../helpers/animations';

const specs = () => {
  const buttons = document.querySelectorAll('.lj-specs__button');
  const overlay = document.querySelector('.lj-specs__overlay');
  const overlayImg = document.querySelector('.lj-specs__overlay-img');
  const closeBtn = document.querySelector('.lj-specs__overlay-close');

  if (buttons.length == 0) return;

  buttons.forEach((button) => {
    button.addEventListener('click', (e) => {
      if (button.classList.contains('active')) {
        buttons.forEach((button) => {
          button.classList.remove('active');
        });
        fadeOut(overlay);
        return;
      }

      overlayImg.srcset = e.target.getAttribute('data-overlay-image');
      fadeIn(overlay);
      buttons.forEach((button) => {
        button.classList.remove('active');
      });
      e.target.classList.add('active');
    });
  });

  closeBtn.addEventListener('click', () => {
    buttons.forEach((button) => {
      button.classList.remove('active');
    });
    fadeOut(overlay);
  });
};

export default specs;
